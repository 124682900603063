import React from "react"

import { renderAst } from "../../utils/renderAst"
import { toHtmlAst } from "../../utils/toHtmlAst"

const LegalContent = ({ copy }) => {
  return (
    <section className="container mx-auto flex flex-wrap px-4 md:px-10 py-6 md:py-8">
      {renderAst(
        {
          p: ({ children }) => <p className="pb-3">{children}</p>,
          h1: ({ children }) => (
            <h1 className="font-semibold text-2xl pb-3">{children}</h1>
          ),
          h2: ({ children }) => (
            <h4 className="font-semibold text-1xl pb-3">{children}</h4>
          ),
        },
        toHtmlAst(copy)
      )}
    </section>
  )
}

export default LegalContent
